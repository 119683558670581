import axios from 'axios'

const axiosService = axios.create({
    baseURL : process.env.VUE_APP_API_URL,
})

function getBssList(sendData) {
    console.log(sendData);
    return axiosService.post('cfg_bss.php', sendData);
}

export {getBssList};
