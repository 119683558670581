<template>
  <div>
    <button @click="changeLocale" style="border: 1px solid red; margin:10px 50px; padding:10px;">
    {{ $t('button.add') }}
    </button>

    <div style="width:300px;height:50px;border:1px dashed darkcyan;margin:0 auto; text-align:center;">
      {{ $t('button.delete') }}
    </div>

    <div style="width:100px; margin:20px auto">
    <v-btn dense @click="getData" >get </v-btn>
  </div>
  <p style="width:100px; margin:20px auto">
    <v-btn dense @click="getData2" >get2 </v-btn>
  </p>

    <p style="width:100px; margin:20px auto">
    <v-btn dense @click="goAboutView" >go View </v-btn>
  </p>
  </div>
</template>

<script>
 import { loginManager } from '@/api/index.js';
 import { getBssList } from '@/api/device.js';

  export default {
    name: 'Home',
    methods: {
      goAboutView() {
      this.$router.replace('/about');
    },

      changeLocale() {
        console.log(this.$i18n.locale);
        if (this.$i18n.locale === 'en') return (this.$i18n.locale = 'ko')
        this.$i18n.locale = 'en'
      },

      async getData() {
        console.log('a');

        const userData = {uid: 'admin', upwd: 'a123456789' }
        
        const {data }= await loginManager(userData);
      
       
        console.log(data.resultCode);

        this.getData2()
      }
      , 
      async getData2() {
        console.log('b');
        const sendData = {usrID: "admin", authCode: "blhTVy85bTlwQUZuY3hWTldPc0xUVkhYU1dZTTcxR1VPWGtXMGNpM0xWST0" };
        let data2 = await getBssList(sendData);
        // let bssData = JSON.stringify(data2.data);
        console.log(data2.data.value.list);
       
      }
    }
  }
</script>
